<template>
  <div id="ParkMGM">
    <WhereWeAreMap :mapData="mapData" />
  </div>
</template>

<script>
import WhereWeAreMap from '@/components/global/WhereWeAreMap'
import { getParkMGMMapData } from "@/data/sectionData/whereWeAreData/parkMGMMapData"

export default {
  name: "ParkMGM",
  components: {
    WhereWeAreMap
  },
  data() {
    return {
      mapData: getParkMGMMapData()
    }
  }
}
</script>

<style lang="scss scoped">

</style>